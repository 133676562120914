<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import Aws from "@/services/Aws";
import modalChangeSecurityGroupRules from "@/components/modals/monitor/aws/modalChangeSecurityGroupRules";

export default {
    components: { Layout, PageHeader, modalChangeSecurityGroupRules },
    data() {
        return {
            title: "Security Groups",
            error: null,
            tableData: [],
            currentPage: 1,
            perPage: 10,
            items: [
                {
                    text: "AWS",
                },
                {
                    text: "AWS Security Groups",
                    active: true,
                },
            ] ,
            pageOptions: [10, 25, 50, 100],
            filter: null,
            filterOn: [],
            sortBy: "GroupName",
            sortDesc: false,
            isBusy: false,
            roles : [],
            fields: [
                {
                    key: "GroupId",
                    label: "Group Id",
                    class: "text-nowrap",
                    sortable: false,
                },
                {
                    key: "VpcId",
                    label: "Vpc Id",
                    class: "text-nowrap",
                    sortable: false,
                },
                {
                    key: "GroupName",
                    label: "Group Name",
                    class: "text-nowrap",
                    sortable: true,
                },
                {
                    key: "Description",
                    label: "Description",
                    sortable: false
                },
                {
                    key: "note",
                    label: "Note",
                    sortable: false,
                },
                "action",
            ],
            totalRows: 0,
            showModal: false,
            securityGroupData:[]
        };
    },
    async mounted() {
        await this.getSecurityGroups()
    },
    methods: {
        async getSecurityGroups(GroupID, action) {
            try {
                this.isBusy = true;
                const response = await Aws.getSecurityGroups();
                this.originalTableData = response.data.data;
                this.tableData = [...this.originalTableData];
                this.totalRows = this.tableData.length;

                if(action == 'updateData' && GroupID != undefined){
                    const groupData = this.tableData.find(item => item.GroupId === GroupID);
                    this.securityGroupData = groupData
                }
            } catch (error) {
                this.error = error.response.data.error ? error.response.data.error : "";
                this.tableData = [];
                this.totalRows = 0;
            } finally {
                this.showLoader = false;
                this.isBusy = false;
            }
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

        callModalChangeSecurityGroupRules(securityGroupData){
            this.$bvModal.show("changeSecurityGroupRules_edit");
            this.securityGroupData = securityGroupData;
        },
    },
    middleware: "authentication",
};
</script>
<template>
    <Layout>
        <PageHeader :title="title" :items="items" />

        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">Total Security Groups: {{totalRows}}</h4>
                        <div class="row mt-4">
                            <div class="col-sm-12 col-md-3">
                                <div class="dataTables_length">
                                    <label class="d-inline-flex align-items-center">
                                        Show&nbsp;
                                        <b-form-select
                                                v-model="perPage"
                                                size="sm"
                                                :options="pageOptions"
                                        ></b-form-select
                                        >&nbsp;entries
                                    </label>
                                </div>
                            </div>


                            <!-- Search -->
                            <div class="col-sm-12 col-md-9">
                                <div
                                        id="tickets-table_filter"
                                        class="dataTables_filter text-md-end"
                                >
                                    <label class="d-inline-flex align-items-center">
                                        Search:
                                        <b-form-input
                                                v-model="filter"
                                                type="search"
                                                placeholder="Search..."
                                                class="form-control form-control-sm ms-2"
                                        ></b-form-input>
                                    </label>
                                </div>
                            </div>
                            <!-- End search -->
                        </div>
                        <!-- Table -->
                        <div class="table-responsive mb-0">
                            <b-table
                                    :busy="isBusy"
                                    :items="tableData"
                                    :fields="fields"
                                    responsive="sm"
                                    :per-page="perPage"
                                    :current-page="currentPage"
                                    :sort-by.sync="sortBy"
                                    :sort-desc.sync="sortDesc"
                                    :filter="filter"
                                    :filter-included-fields="filterOn"
                                    show-empty
                                    empty-text="No Data Found"
                                    @filtered="onFiltered"
                            >

                                <template v-slot:cell(action)="data">
                                    <ul class="list-inline mb-0">
                                        <li class="list-inline-item" v-if="data.item.allowActions">
                                            <a
                                                    href="javascript:void(0);"
                                                    @click="callModalChangeSecurityGroupRules(data.item)"
                                                    class="px-2 text-primary"
                                                    title="Change Security Group Rules"
                                            >
                                                <i class="uil uil-pen font-size-18"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </template>

                                <template #table-busy>
                                    <div class="text-center text-danger my-2">
                                        <b-spinner class="align-middle"></b-spinner>
                                        <strong>Loading...</strong>
                                    </div>
                                </template>

                            </b-table>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="dataTables_paginate paging_simple_numbers float-end">
                                    <ul class="pagination pagination-rounded mb-0">
                                        <!-- pagination -->
                                        <b-pagination
                                                v-model="currentPage"
                                                :total-rows="totalRows"
                                                :per-page="perPage"
                                        ></b-pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <modalChangeSecurityGroupRules
                :securityGroupData="this.securityGroupData"
                @onRefresh="getSecurityGroups"
        ></modalChangeSecurityGroupRules>

    </Layout>
</template>
